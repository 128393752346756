<template>
    <div>
        <iframe ref="iframe" id="bdIframe" :src="bdTokenUrl" frameborder="0" scrolling="no" ></iframe>
    </div>
</template>

<script>

    export default {
        name: "Metaclassification",
        data() {
            return {
                bdTokenUrl: `http://${this.$store.state.metaDataIp}:21000/index.html#!/tag/tagAttribute/`
            }
        },
        created() {
            this.getUrl();
            this.$nextTick(()=>{
                this.getCode();
            });
        },
        mounted(){
            /**
             * iframe-宽高自适应显示   
             */
            const oIframe = document.getElementById('bdIframe');
            const deviceWidth = document.documentElement.clientWidth;
            const deviceHeight = document.documentElement.clientHeight;
            oIframe.style.width = (Number(deviceWidth)) + 'px'; //数字是页面布局宽度差值
            oIframe.style.height = (Number(deviceHeight)-123) + 'px'; //数字是页面布局高度差
        },
        methods: {
            /**
             * 获取-外部接口信息
             */
            getUrl() {
                let that = this
                let bdUrl = {queryurl: this.$paths.bdpath+'/locate'};
                this.$api.getBdToken(bdUrl,function(res) {
                    that.bdTokenUrl = res.data.data;
                })
            },
       }
    }
</script>
